const stampColors = [
  { main: '#344463', light: '#dfe1e6' }, // fallback color
  { main: '#5ba4cf', light: '#bcd9ea' },
  { main: '#7bc86c', light: '#d6ecd2' },
  { main: '#ffaf3f', light: '#fce6c6' },
  { main: '#ef7564', light: '#f5d3ce' },
  { main: '#f5dd2a', light: '#faf3c0' },
  { main: '#cd8de5', light: '#eddbf4' },
  { main: '#ff8ed4', light: '#fcdcef' },
  { main: '#29cce5', light: '#bdecf3' },
  { main: '#6deca9', light: '#d3f6e4' },
  { main: '#344463', light: '#dfe1e6' },
];

// maximum number of currently permitted items in library
const defaultMaxItems = 1000;

const defaultStampTypes = ['Melody', 'Drums', 'Vocals'];

const clipboardItemReservedId = '_clipboard';

export {
  stampColors,
  clipboardItemReservedId,
  defaultStampTypes,
  defaultMaxItems,
};
